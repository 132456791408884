import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
  listItem: {
    backgroundColor: '#fff',
    boxShadow: '0 0 15px 1px rgba(0,0,0,0.08)',
    display: 'flex',
    alignItems: 'center',
  },
  listItemContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    paddingLeft: 0,
    marginLeft: theme.spacing(1),
  },
  listItemTitle: {
    fontSize: 13,
    marginBottom: theme.spacing(0.5),
  },
  listItemSubtitle: {
    fontSize: 10,
  },
  jobLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

const JobListItem = ({ job }) => {
  const classes = useStyles();

  const displaydate = format(new Date(job.date), 'dd/MM/yyyy');

  return (
    <a href={job.link} target="_blank" rel="noreferrer" className={classes.jobLink}>
      <Box className={classes.listItem}>
        <Box className={classes.listItemContent}>
          <Box>
            <Typography variant="inherit" component="p" className={classes.listItemTitle}>
              {job.title}
            </Typography>
            <Typography variant="inherit" component="p" className={classes.listItemSubtitle}>
              {job.location}
            </Typography>
          </Box>
          <Box alignSelf="flex-end">
            <Typography variant="inherit" component="p" className={classes.listItemSubtitle}>
              {displaydate}
            </Typography>
          </Box>
        </Box>
      </Box>
    </a>
  );
};

export default JobListItem;
