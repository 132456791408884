import React from 'react';

import { AppBar, Box, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAuth } from '../context/auth';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#fff',
  },
  img: {
    width: 111,
  },
  emailContainer: {
    borderRight: `1px solid ${theme.palette.text.primary}`,
  },
  email: {
    fontSize: 11,
  },
  logout: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: 11,
  },
}));

const TopNav = () => {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <AppBar position="relative" className={classes.container} color="inherit">
      <Toolbar>
        <Link to="/">
          <img src={'/images/logo-partech.svg'} alt="Partech Logo" className={classes.img} />
        </Link>
        {auth.user && (
          <Box ml="auto" display="flex" alignItems="center">
            <Box className={classes.emailContainer} mr={1} pr={1}>
              <Typography className={classes.email}>{auth.user.email}</Typography>
            </Box>
            <Box onClick={() => auth.signout()}>
              <Typography className={classes.logout}>LOGOUT</Typography>
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
