import { isWithinInterval, subDays } from 'date-fns';

const filterDateRange = (data, range) => {
  if (range) {
    if (data) {
      return data.filter(item => {
        return isWithinInterval(new Date(item.date), {
          start: subDays(new Date(), range),
          end: new Date(),
        });
      });
    }
  } else {
    return data;
  }
};

export default filterDateRange;
