import React, { useEffect, useState } from 'react'; // eslint-disable-line

import { useHistory } from 'react-router';

import { Box, Button, Container, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ClearIcon from '@material-ui/icons/Clear';

import API_BASE_URL from '../lib/API_BASE_URL';
import axios from 'axios';
import useSWR from 'swr';

import CTable from '../components/CTable';
import SpecificTable from '../components/SpecificTable';
import SearchBar from '../components/SearchBar';

import getParameterByName from '../utils/getParameterByName';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 'calc(100vh - 56px)',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      minHeight: 'calc(100vh - 48px)',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 64px)',
    },
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  title: {
    fontSize: 28,
  },
  smallTitle: {
    fontSize: 18,
  },
  smallSubtitle: {
    fontSize: 10,
  },
  filterBubble: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 100,
    height: 28,
    width: 'fit-content',
    color: theme.palette.primary.main,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    fontWeight: 400,
    marginRight: theme.spacing(1),
  },
}));

const HomePage = ({ watchList }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [filters, setFilters] = useState([]);
  const [filterCount, setFilterCount] = useState(0);

  const [initOrder, setInitOrder] = useState(1); // eslint-disable-line
  const [initOrderBy, setInitOrderBy] = useState('name'); // eslint-disable-line

  const addFilter = query => {
    if (!filters.includes(query)) {
      setFilters([...filters, query]);
    }
  };

  const removeFilter = index => {
    const arr = [...filters];
    arr.splice(index, 1);
    setFilters([...arr]);
  };

  const fetcher = url => axios.get(url, { withCredentials: true }).then(res => res.data);

  const { data, error } = useSWR(`${API_BASE_URL}/api/v1/companies`, fetcher);
  // const data = undefined;

  const [rows, setRows] = useState(data?.companies);

  useEffect(() => {
    if (rows) {
      const filter = getParameterByName('filter');
      if (filter) {
        addFilter(filter);
        history.replace('/');
      }
    }
  }, [rows]); // eslint-disable-line

  const getSmallTableRows = (orderBy, rows, watchList) => {
    if (rows) {
      const array = [...rows];
      if (orderBy === 'watchlist') {
        return [...array.filter(company => watchList?.includes(company.key)).slice(0, 5)];
      } else {
        let order = -1;
        array.sort((a, b) => {
          if (a[orderBy] < b[orderBy]) {
            return -1 * order;
          }
          if (a[orderBy] > b[orderBy]) {
            return 1 * order;
          }
          return 0;
        });
        return [...array.slice(0, 5)];
      }
    } else {
      return undefined;
    }
  };

  // const [positionRows, setPositionRows] = useState(getSmallTableRows('position', data?.companies));
  // const [growthRows, setGrowthRows] = useState(getSmallTableRows('growth', data?.companies));
  const [watchlistRows, setWatchlistRows] = useState(
    getSmallTableRows('watchlist', data?.companies, watchList)
  );

  useEffect(() => {
    if (data) {
      setRows(data.companies);
      // setPositionRows(getSmallTableRows('position', data.companies));
      // setGrowthRows(getSmallTableRows('growth', data.companies));
      setWatchlistRows(getSmallTableRows('watchlist', data.companies, watchList));
    }
  }, [data]); // eslint-disable-line

  // useEffect(() => {
  //   const sample = [
  //     ['Acatus', 'Medical', 'Berlin, Germany', 21, 102, 3, true],
  //     ['Advano', 'Medical', 'New Orleans, United States', 38, 321, 1, false],
  //     ['Akridata', 'Technology', 'San Francisco, United States', 100, 643, 1, true],
  //     ['Alan', 'Legal', 'Paris, France', 50, 124, -1, false],
  //     ['Alkemics', 'Technology', 'Paris, France', 10, 436, 0, false],
  //   ];
  //   function createData(key, name, team, location, employee_range, position, growth, watchlist) {
  //     return { key, name, team, location, employee_range, position, growth, watchlist };
  //   }
  //   const tempRows = [];
  //   for (let i = 0; i < 100; i += 1) {
  //     const randomSelection = sample[Math.floor(Math.random() * sample.length)];
  //     tempRows.push(createData(i, ...randomSelection));
  //   }
  //   // for (let i = 0; i < 5; i += 1) {
  //   //   tempRows.push(createData(i, ...sample[i]));
  //   // }
  //   setRows(tempRows);
  //   setPositionRows(getSmallTableRows('position', tempRows));
  //   setGrowthRows(getSmallTableRows('growth', tempRows));
  //   setWatchlistRows(getSmallTableRows('watchlist', tempRows));
  // }, []);

  if (error) {
    return (
      <Box className={classes.container} display="flex" alignItems="center" justifyContent="center">
        <Typography>Something went wrong</Typography>
      </Box>
    );
  }

  const hasFilter = filters.length !== 0;

  return (
    <Container className={classes.container}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {hasFilter ? (
          <Box>
            <Typography variant="h4" component="h1" className={classes.smallTitle}>
              Search Results
            </Typography>
            <Typography variant="caption" component="h3" className={classes.smallSubtitle}>
              {filterCount} companies found matching your search
            </Typography>
          </Box>
        ) : (
          <Typography variant="h4" component="h1" className={classes.title}>
            All Companies
          </Typography>
        )}

        <Box flex={1} maxWidth={350}>
          <SearchBar addFilter={addFilter} disabled={!data} />
        </Box>
      </Box>
      {hasFilter && (
        <Box mt={2} display="flex">
          {filters.map((filter, index) => (
            <Button
              key={filter}
              className={classes.filterBubble}
              onClick={() => removeFilter(index)}
            >
              {filter}
              <Box display="flex" ml={0.5}>
                <ClearIcon fontSize="inherit" />
              </Box>
            </Button>
          ))}
          <Box color={theme.palette.error.main}>
            <Button color="inherit" style={{ fontSize: 10 }} onClick={() => setFilters([])}>
              CLEAR ALL
            </Button>
          </Box>
        </Box>
      )}
      <Box mt={3}>
        <CTable
          filters={filters}
          rows={rows}
          setFilterCount={setFilterCount}
          initOrder={initOrder}
          initOrderBy={initOrderBy}
          watchList={watchList}
        />
      </Box>
      {!hasFilter && (
        <Box mt={4} display="flex">
          {/* <Box flex={1} mr={1}>
            <SpecificTable
              title="Top 5"
              rows={positionRows}
              btnFunc={() => {
                setInitOrder(-1);
                setInitOrderBy('position');
              }}
            />
          </Box>
          <Box flex={1} mx={1}>
            <SpecificTable
              title="Biggest Climbers"
              rows={growthRows}
              btnFunc={() => {
                setInitOrder(-1);
                setInitOrderBy('growth');
              }}
            />
          </Box> */}
          <Box flex={1}>
            <SpecificTable
              title="My Watchlist"
              rows={watchlistRows}
              btnFunc={() => {
                addFilter('my watchlist');
              }}
            />
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default HomePage;
