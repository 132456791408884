import React from 'react';

const GrowthSameIcon = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 4" width={width} height={height}>
      <path d="M1 1h8v2H1z" fill="currentColor" stroke="currentColor" fillRule="evenodd" />
    </svg>
  );
};

export default GrowthSameIcon;
