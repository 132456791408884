import React from 'react';

const GrowthUpIcon = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 12" width={width} height={height}>
      <path
        d="M7.52271598 11c.24824781 0 .44949243-.19794517.44949243-.4421229V5.69452518h1.57681948c.18416868.00058996.35007042-.10938379.41864013-.27751018.0685697-.1681264.02593262-.36038587-.10759136-.48515182L5.81059928 1.12253226c-.1737567-.16337635-.44744186-.16337635-.62119856 0L1.13992334 4.93186318c-.13352398.12476595-.17616106.31702542-.10759136.48515182.0685697.16812639.23447145.27810014.41864013.27751018h1.57681948v4.86335192c0 .24417773.20124462.4421229.44949243.4421229h4.04543196z"
        fill="currentColor"
        stroke="currentColor"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GrowthUpIcon;
