import React, { useEffect, useState } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useHistory } from 'react-router-dom';

import { FixedSizeList as List } from 'react-window';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(theme => ({
  table: {
    // minWidth: 650,
  },
  tableHead: {
    boxShadow: '0 0 15px 1px rgba(0,0,0,0.08)',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  tableHeadCell: {
    padding: '4px 16px',
    backgroundColor: '#fff',
    display: 'flex',
  },
  tableRow: {
    backgroundColor: '#fff',
    boxShadow: '0 0 15px 1px rgba(0,0,0,0.08)',
    display: 'flex',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 130, 200, 0.1)',
    },
  },
  tableRowCell: {
    padding: '4px 16px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const CTable = ({ filters, rows, setFilterCount, initOrder, initOrderBy, watchList }) => {
  const classes = useStyles();
  const theme = useTheme();

  const hasFilter = filters.length !== 0;

  const [order, setOrder] = React.useState(initOrder);
  const [orderBy, setOrderBy] = React.useState(initOrderBy);

  useEffect(() => {
    setOrder(initOrder);
    setOrderBy(initOrderBy);
  }, [initOrder, initOrderBy]);

  const columns = [
    { id: 'name', label: 'company name', width: '100%' },
    { id: 'location', label: 'location', width: '100%' },
    { id: 'team', label: 'team', width: '100%' },
    { id: 'employee_range', label: 'employees', width: 100 },
    // { id: 'position', label: 'position', width: 100 },
    { id: 'growth', label: 'growth', width: 100 },
  ];

  const [filterArr, setFilterArr] = useState(rows);

  useEffect(() => {
    setFilterArr(rows);
  }, [rows]);

  useEffect(() => {
    if (filterArr) {
      const array = [...filterArr];
      array.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return -1 * order;
        }
        if (a[orderBy] > b[orderBy]) {
          return 1 * order;
        }
        return 0;
      });
      setFilterArr(array);
    }
  }, [order, orderBy]); // eslint-disable-line

  const handleSortClick = column => {
    if (filterArr !== undefined) {
      if (orderBy === column.id) {
        setOrder(order === 1 ? -1 : 1);
      } else {
        setOrder(1);
      }
      setOrderBy(column.id);
    }
  };

  const searchColumns = [
    'name',
    'team',
    'location',
    'employee_range',
    'position',
    'growth',
    'watchlist',
  ];

  useEffect(() => {
    if (filters.length !== 0) {
      let arr = [...rows];
      arr = arr.filter(company => {
        let match = false;
        filters.every(filter => {
          return searchColumns.some(column => {
            if (column === 'watchlist') {
              if (filter.toLowerCase().includes('watchlist')) {
                match = watchList.includes(company.key);
              }
            } else if (typeof company[column] === 'string') {
              match = company[column].toLowerCase().includes(filter.toLowerCase());
            } else {
              match = company[column] === filter * 1;
            }
            return match;
          });
        });
        return match;
      });

      setFilterArr(arr);
      setFilterCount(arr.length);
    } else {
      setFilterArr(rows);
    }
  }, [filters]); // eslint-disable-line

  const NameCell = ({ row }) => {
    return (
      <Box display="flex" alignItems="center">
        {row.profile_image_url ? (
          <img
            src={row.profile_image_url}
            alt="profile_image"
            width={31}
            height={31}
            onError={ev => (ev.target.src = '/images/account-circle.svg')}
          />
        ) : (
          <img src={'/images/account-circle.svg'} alt="account-circle" width={31} height={31} />
        )}
        <Box ml={1}>
          <Box fontSize={13}>{row.name}</Box>
          <Box fontSize={10}>{row.category}</Box>
        </Box>
      </Box>
    );
  };

  const Row = ({ index, style }) => {
    const history = useHistory();

    const row = filterArr[index];
    return (
      <Box key={row.key} style={style}>
        <Box className={classes.tableRow} onClick={() => history.push(`/company/${row.key}`)}>
          <Box className={classes.tableRowCell} flex={1}>
            <NameCell row={row} />
          </Box>
          <Box className={classes.tableRowCell} flex={1}>
            <Box fontSize={10}>{row.location}</Box>
          </Box>
          <Box className={classes.tableRowCell} flex={1}>
            <Box fontSize={10}>{row.team}</Box>
          </Box>
          <Box className={classes.tableRowCell} width={columns[3].width}>
            <Box fontSize={10}>{row.employee_range}</Box>
          </Box>
          {/* <Box className={classes.tableRowCell} width={columns[4].width}>
            <Box fontSize={10}>{row.position}</Box>
          </Box> */}
          <Box justifyContent="flex-end" className={classes.tableRowCell} width={columns[4].width}>
            <Box fontSize={12} display="flex" justifyContent="flex-end" alignItems="center">
              {row.growth > 0 && '+'}
              {row.growth === 0 ? '=' : row.growth}
              <Box display="flex" ml={0.5}>
                {row.growth > 0 ? (
                  <img src={'images/growth-up.svg'} alt="growth Up" width={11} height={12} />
                ) : row.growth < 0 ? (
                  <img src={'images/growth-down.svg'} alt="growth Down" width={11} height={12} />
                ) : (
                  <img src={'images/growth-same.svg'} alt="growth Same" width={10} height={4} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.table}>
      <Box className={classes.tableHead}>
        {columns.map((column, index) => (
          <Box
            key={index}
            onClick={() => handleSortClick(column)}
            className={classes.tableHeadCell}
            minWidth={column.width === '100%' ? 100 : column.width}
            width={column.width}
            justifyContent={index === columns.length - 1 ? 'flex-end' : 'flex-start'}
            style={{ cursor: filterArr === undefined ? 'default' : 'pointer', userSelect: 'none' }}
          >
            <Box
              color={theme.palette.primary.main}
              fontSize={10}
              fontWeight="bold"
              display="flex"
              alignItems="center"
            >
              {column.label.toUpperCase()}
              <Box display="flex" width={16} fontSize={16}>
                {orderBy === column.id ? (
                  order === 1 ? (
                    <KeyboardArrowDownIcon fontSize="inherit" />
                  ) : (
                    <KeyboardArrowUpIcon fontSize="inherit" />
                  )
                ) : null}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        bgcolor={hasFilter ? (filterArr.length > 7 ? '#fff' : 'transparent') : '#fff'}
        boxShadow={hasFilter ? '' : '0 0 15px 1px rgba(0,0,0,0.08)'}
        minHeight={hasFilter ? 'unset' : 440}
      >
        {filterArr === undefined ? (
          <>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={47} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={47} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={47} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={47} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={47} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={47} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={47} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={47} />
            </Box>
          </>
        ) : (
          <List height={440} itemCount={filterArr.length} itemSize={55}>
            {Row}
          </List>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(CTable);
