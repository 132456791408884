import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import SpecificList from './SpecificList';
import AutoSearchBar from '../AutoSearchBar';

const getSmallTableRows = (orderBy, rows, watchList) => {
  if (rows) {
    const array = [...rows];
    if (orderBy === 'watchlist') {
      return [...array.filter(company => watchList?.includes(company.key))];
    } else {
      let order = -1;
      array.sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return -1 * order;
        }
        if (a[orderBy] > b[orderBy]) {
          return 1 * order;
        }
        return 0;
      });
      return [...array.slice(0, 5)];
    }
  } else {
    return undefined;
  }
};

const LeftPanel = ({ companies, watchList }) => {
  // const [positionRows, setPositionRows] = useState(getSmallTableRows('position', companies));
  // const [growthRows, setGrowthRows] = useState(getSmallTableRows('growth', companies));
  const [watchlistRows, setWatchlistRows] = useState(getSmallTableRows('watchlist', companies));

  useEffect(() => {
    if (companies) {
      // setPositionRows(getSmallTableRows('position', companies));
      // setGrowthRows(getSmallTableRows('growth', companies));
      setWatchlistRows(getSmallTableRows('watchlist', companies, watchList));
    }
  }, [companies, watchList]); // eslint-disable-line

  return (
    <>
      {companies ? (
        <AutoSearchBar companies={companies} />
      ) : (
        <Box display="flex">
          <Box flex={1} mt={1} mb={0.5}>
            <Skeleton variant="rect" width="100%" height={32.875} />
          </Box>
        </Box>
      )}
      {/* <Box mt={3}>
        <SpecificList title="TOP 5" rows={positionRows} />
      </Box>
      <Box mt={3}>
        <SpecificList title="BIGGEST CLIMBERS" rows={growthRows} />
      </Box> */}
      <Box mt={3}>
        <SpecificList title="MY WATCHLIST" rows={watchlistRows} />
      </Box>
    </>
  );
};
export default LeftPanel;
