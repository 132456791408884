import React, { memo, useEffect, useRef, useState } from 'react';

import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import { format } from 'date-fns';

import API_BASE_URL from '../../lib/API_BASE_URL';
import { useSWRInfinite } from 'swr';
import { CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from 'react-virtualized';

const cache = new CellMeasurerCache({
  defaultHeight: 150,
  fixedWidth: true,
});

const useStyles = makeStyles(theme => ({
  feedTitle: {
    fontSize: 18,
  },
  tweetLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  tweetImg: {
    border: '1px solid #D8D8D8',
    borderRadius: 100,
  },
  tweetTitle: {
    fontSize: 10,
    fontWeight: 700,
    maxWidth: 130,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tweetSubtitle: {
    fontSize: 8,
  },
  tweetPara: {
    fontSize: 10,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    '& > span': {
      color: theme.palette.primary.main,
    },
  },
  menuBtn: {
    fontSize: 12,
    textTransform: 'capitalize',
    '& .MuiButton-endIcon': {
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 19,
      },
    },
  },
}));

const TweetComp = ({ item, registerChild, style, measure }) => {
  const classes = useStyles();

  useEffect(() => {
    measure();
  }, []); // eslint-disable-line

  const { tweet } = item;

  const tweetDate = new Date(tweet.created_at);
  const displaydate = format(tweetDate, 'dd/MM/yyyy');

  const today = new Date();

  const dateDiff = Math.round((today.getTime() - tweetDate.getTime()) / (1000 * 3600 * 24));

  let displayText = [];

  tweet.components.forEach((comp, index) => {
    switch (comp.type) {
      case 'text':
        displayText.push(comp.value);
        break;

      case 'hashtag':
        displayText.push(<span key={`${comp.value}${index}`}>#{comp.value}</span>);
        break;

      case 'mention':
        displayText.push(<span key={`${comp.value}${index}`}>@{comp.value}</span>);
        break;

      case 'url':
        displayText.push(
          <span key={`${comp.value}${index}`}>{comp.display_url || comp.value}</span>
        );
        break;

      default:
        displayText.push(comp.value);
        break;
    }
  });

  return (
    <a
      ref={registerChild}
      href={tweet.url}
      target="_blank"
      rel="noreferrer"
      className={classes.tweetLink}
      style={style}
    >
      <Box border="1px solid #DBDBDB" p={1.5} mb={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {tweet.author.profile_image_url ? (
              <img
                src={tweet.author.profile_image_url}
                alt="profile_image"
                width={24}
                height={24}
                onError={ev => (ev.target.src = '/images/account-circle.svg')}
                className={classes.tweetImg}
              />
            ) : (
              <img
                src={'/images/account-circle.svg'}
                alt="account-circle"
                width={24}
                height={24}
                className={classes.tweetImg}
              />
            )}

            <Box ml={0.5} display="flex" flexDirection="column">
              <Typography className={classes.tweetTitle}>{tweet.author.name}</Typography>
              <Typography variant="caption" className={classes.tweetSubtitle}>
                @{tweet.author.username}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box textAlign="right">
              <Typography className={classes.tweetTitle}>
                {item.type === 'twitter_mention' ? 'MENTION' : 'TWEET'}
              </Typography>
              <Typography variant="caption" className={classes.tweetSubtitle}>
                {displaydate} - {dateDiff === 0 ? 'Today' : `${dateDiff} Days`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={1}>
          <Typography className={classes.tweetPara}>{displayText}</Typography>
        </Box>
      </Box>
    </a>
  );
};

const RightPanel = ({ id }) => {
  const classes = useStyles();

  const [hasMore, setHasMore] = useState(true);
  const [tweetTypeFilter, setTweetTypeFilter] = useState('all');

  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.feed) return null;

    if (pageIndex === 0) return [`${API_BASE_URL}/api/v1/companies/${id}/activities`, ''];

    return [`${API_BASE_URL}/api/v1/companies/${id}/activities`, previousPageData.next_cursor];
  };

  const fetcher = async (url, cursor = '') => {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        cursor: cursor,
        size: 100,
      }),
    });
    return await res.json();
  };

  const totalRows = useRef(null);

  let activities = [];
  const { data: activitiesData, size, setSize } = useSWRInfinite(getKey, fetcher);

  if (activitiesData) {
    if (activitiesData.length !== 0) {
      totalRows.current = activitiesData[0].total;
      if (!activitiesData[activitiesData.length - 1].has_more && hasMore) {
        setHasMore(false);
      }
      activitiesData.forEach(page => {
        activities = [...activities, ...page.feed];
        if (tweetTypeFilter !== 'all') {
          activities = activities.filter(value => {
            let filter = tweetTypeFilter;
            if (filter === 'mention') {
              filter = 'twitter_mention';
            }
            return value.type === filter;
          });
        }
      });
    }
  }

  const [listHeight, setListHeight] = useState(0);

  useEffect(() => {
    totalRows.current = null;
    if (!hasMore) {
      setHasMore(true);
    }

    const rightPanelElement = document.getElementById('rightPanelId');
    if (rightPanelElement) {
      setListHeight(rightPanelElement.clientHeight - 48 - 22 - 16);
    }
  }, [id]); // eslint-disable-line

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = newType => {
    setTweetTypeFilter(newType);
    handleMenuClose();
  };

  const isRowLoaded = ({ index }) => {
    return !!activities[index];
  };

  const rowRenderer = ({ index, key, parent, style }) => {
    const item = activities[index];

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ measure, registerChild }) => (
          <TweetComp
            registerChild={registerChild}
            key={`${item.tweet.id}${index}`}
            item={item}
            style={style}
            measure={measure}
          />
        )}
      </CellMeasurer>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" px={3}>
        {activities.length !== 0 || totalRows.current === 0 ? (
          <>
            <Typography variant="h4" component="h2" className={classes.feedTitle}>
              Activity Feed
            </Typography>
            <Box display="flex" alignItems="center">
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
                endIcon={<KeyboardArrowDownRoundedIcon />}
                size="small"
                className={classes.menuBtn}
                disabled={totalRows.current === 0}
              >
                {tweetTypeFilter}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleSelect('all')} selected={tweetTypeFilter === 'all'}>
                  All
                </MenuItem>
                <MenuItem
                  onClick={() => handleSelect('tweet')}
                  selected={tweetTypeFilter === 'tweet'}
                >
                  Tweet
                </MenuItem>
                <MenuItem
                  onClick={() => handleSelect('mention')}
                  selected={tweetTypeFilter === 'mention'}
                >
                  Mention
                </MenuItem>
              </Menu>
            </Box>
          </>
        ) : (
          <Skeleton width="100%" height={29} />
        )}
      </Box>
      <Box mt={2} px={3}>
        {activities.length !== 0 || totalRows.current === 0 ? (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={() => setSize(size + 1)}
            rowCount={totalRows.current}
            minimumBatchSize={100}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                height={listHeight}
                width={277}
                rowCount={activities.length}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                noRowsRenderer={() => (
                  <Typography variant="caption">No items in this list</Typography>
                )}
              />
            )}
          </InfiniteLoader>
        ) : (
          <>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={150} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={150} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={150} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={150} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={150} />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default memo(RightPanel);
