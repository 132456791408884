import React from 'react';

import { useHistory } from 'react-router-dom';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 18,
  },
  table: {
    backgroundColor: '#fff',
    boxShadow: '0 0 15px 1px rgba(0,0,0,0.08)',
    minHeight: 195,
  },
  tableRow: {
    backgroundColor: '#fff',
    boxShadow: '0 0 15px 1px rgba(0,0,0,0.08)',
    display: 'flex',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 130, 200, 0.1)',
    },
  },
  tableRowCell: {
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
  },
  viewAllBtn: {
    fontSize: 10,
    fontWeight: 'bold',
  },
}));

const SpecificTable = ({ title, rows, btnFunc }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box mb={2}>
        <Typography variant="h4" component="h2" className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Box className={classes.table}>
        {rows === undefined ? (
          <>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={31} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={31} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={31} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={31} />
            </Box>
            <Box py={0.5}>
              <Skeleton variant="rect" width="100%" height={31} />
            </Box>
          </>
        ) : rows.length !== 0 ? (
          rows.map(row => {
            return (
              <Box
                key={row.key}
                className={classes.tableRow}
                onClick={() => history.push(`/company/${row.key}`)}
              >
                <Box className={classes.tableRowCell} flex={1}>
                  <Box display="flex" alignItems="center">
                    {row.profile_image_url ? (
                      <img
                        src={row.profile_image_url}
                        alt="profile_image"
                        width={19}
                        height={19}
                        onError={ev => (ev.target.src = '/images/account-circle.svg')}
                      />
                    ) : (
                      <img
                        src={'/images/account-circle.svg'}
                        alt="account-circle"
                        width={19}
                        height={19}
                      />
                    )}
                    <Box ml={1}>
                      <Box fontSize={12}>{row.name}</Box>
                    </Box>
                  </Box>
                </Box>
                <Box justifyContent="flex-end" className={classes.tableRowCell} width={150}>
                  <Box fontSize={12} display="flex" justifyContent="flex-end" alignItems="center">
                    {row.growth > 0 && '+'}
                    {row.growth === 0 ? '=' : row.growth}
                    <Box display="flex" ml={0.5}>
                      {row.growth > 0 ? (
                        <img src={'/images/growth-up.svg'} alt="growth Up" width={11} height={12} />
                      ) : row.growth < 0 ? (
                        <img
                          src={'/images/growth-down.svg'}
                          alt="growth Down"
                          width={11}
                          height={12}
                        />
                      ) : (
                        <img
                          src={'/images/growth-same.svg'}
                          alt="growth Same"
                          width={10}
                          height={4}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box className={classes.tableRowCell}>
            <Typography variant="caption">No items in this list</Typography>
          </Box>
        )}
      </Box>
      <Box mt={0.5}>
        <Button
          size="small"
          color="primary"
          className={classes.viewAllBtn}
          onClick={btnFunc}
          disabled={rows === undefined}
        >
          VIEW ALL
        </Button>
      </Box>
    </>
  );
};

export default React.memo(SpecificTable);
