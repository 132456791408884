import React, { useContext, createContext, useState, useEffect } from 'react';

import { Route, useHistory, useLocation } from 'react-router-dom';

import API_BASE_URL from '../lib/API_BASE_URL';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

// const FetchTest = () => {
//   useEffect(() => {
//     // Test if credentials  worked
//     fetch(`${API_BASE_URL}/login/test`, {
//       credentials: 'include', // provide cookie for authentication
//     })
//       .then(r => r.text())
//       .then(r => {
//         console.log('Login test:', r);
//       });

//     // Test if you can fetch company info
//     fetch(`${API_BASE_URL}/api/v1/companies/ab-tasty`, {
//       credentials: 'include', // provide cookie for authentication
//     })
//       .then(r => {
//         return r.json();
//       })
//       .then(res => {
//         console.log('Fetch ab-tasty:', res);
//       });
//   });
//   return null;
// };

// export const App = () => {
//   const [isLogged, setIsLogged] = useState(false);

//   useEffect(() => {
//     if (window.location.pathname === '/authenticated') {
//       setIsLogged(true);
//       return;
//     }
//   }, []);

//   return (
//     <div>
//       {!isLogged && (
//         <button
//           onClick={() => {
//             // Redirect user to api/login
//             window.location.href = `${API_BASE_URL}/login`;
//             // Will internally redirect user to microsoft login
//             // If successfull api will set some cookie and redirect user to http://localhost:3000/authenticated
//             // (app is expected to run http://localhost:3000)
//           }}
//         >
//           LOGIN
//         </button>
//       )}
//       {isLogged && (
//         <div>
//           Logged in !
//           <FetchTest />
//         </div>
//       )}
//     </div>
//   );
// };

const useAuthProvider = () => {
  const [user, setUser] = useState(null);

  const signin = cb => {
    window.location.href = `${API_BASE_URL}/login`;
  };

  const signout = async cb => {
    try {
      const res = await axios.post(`${API_BASE_URL}/api/v1/user/me/logout`, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log(res);
      setUser(null);
    } catch (error) {
      console.log('signout error', error);
      console.log(error.response);
    }

    if (cb) cb();
  };

  const getUser = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}/api/v1/user/me`, {
        withCredentials: true,
      });
      setUser(res.data);
    } catch (error) {
      console.log('getUser error', error);
    }
  };

  const testLogin = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}/login/test`, {
        withCredentials: true,
      });
      if (res.data.success) {
        getUser();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('login test error', error);
      console.log(error.response);
      return false;
    }
  };

  return {
    user,
    signin,
    signout,
    testLogin,
  };
};

export const ProtectedRoute = ({ children, ...rest }) => {
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();

  // const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);

  useEffect(() => {
    (async () => {
      setIsLoggedIn(await auth.testLogin());
    })();
  }, []); // eslint-disable-line

  if (isLoggedIn === undefined) {
    return null;
  }

  if (isLoggedIn) {
    return <Route {...rest} render={() => children} />;
  } else {
    const { from } = location.state || { from: { pathname: '/' } };
    auth.signin(() => history.replace(from));
    return null;
  }
};
