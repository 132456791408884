import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
  listItem: {
    backgroundColor: '#fff',
    boxShadow: '0 0 15px 1px rgba(0,0,0,0.08)',
    display: 'flex',
    alignItems: 'center',
  },
  inoutBar: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.6),
    '& > div': {
      width: 5,
      alignSelf: 'stretch',
    },
    '& > img': {
      marginLeft: -1,
    },
  },
  listItemContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    paddingLeft: 0,
    marginLeft: theme.spacing(1),
    overflow: 'hidden',
  },
  personImg: {
    border: '1px solid #D8D8D8',
    borderRadius: 100,
  },
  listItemTitle: {
    fontSize: 13,
    marginBottom: theme.spacing(0.5),
  },
  listItemSubtitle: {
    fontSize: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  employeeLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

const InOutListItem = ({ employee, style }) => {
  const classes = useStyles();

  const displaydate = format(new Date(employee.date), 'dd/MM/yyyy');

  return (
    <a
      href={employee.person.profile_url}
      target="_blank"
      rel="noreferrer"
      className={classes.employeeLink}
      style={style}
    >
      <Box className={classes.listItem}>
        <Box className={classes.inoutBar}>
          <Box bgcolor={employee.direction === 'in' ? '#14B14D' : '#B11414'} />
          <img
            src={employee.direction === 'in' ? '/images/inbound.svg' : '/images/outbound.svg'}
            alt="inout icon"
            width={9}
            height={10}
          />
        </Box>
        {employee.person.profile_image_url ? (
          <img
            src={employee.person.profile_image_url}
            alt="profile_image"
            width={31}
            height={31}
            onError={ev => (ev.target.src = '/images/account-circle.svg')}
            className={classes.personImg}
          />
        ) : (
          <img
            src={'/images/account-circle.svg'}
            alt="account-circle"
            width={31}
            height={31}
            className={classes.personImg}
          />
        )}
        <Box className={classes.listItemContent}>
          <Box overflow="hidden" mr={1}>
            <Typography variant="inherit" component="p" className={classes.listItemTitle}>
              {!employee.person.first_name && !employee.person.last_name
                ? 'Unknown'
                : `${employee.person.first_name} ${employee.person.last_name}`}
            </Typography>
            <Typography variant="inherit" component="p" className={classes.listItemSubtitle}>
              {employee.job.title}
            </Typography>
          </Box>
          <Box alignSelf="flex-end">
            <Typography variant="inherit" component="p" className={classes.listItemSubtitle}>
              {displaydate}
            </Typography>
          </Box>
        </Box>
      </Box>
    </a>
  );
};

export default InOutListItem;
