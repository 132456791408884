import React, { memo } from 'react';

import { useHistory } from 'react-router';

import { Box, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';

const useStyles = makeStyles(theme => ({
  chartBox: {
    backgroundColor: '#fff',
    boxShadow: '0 0 15px 1px rgba(0,0,0,0.08)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    cursor: 'pointer !important',
    '& .recharts-wrapper': {
      cursor: 'pointer !important',
    },
    '& > div:first-of-type': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      '& > span': {
        fontSize: 12,
        fontWeight: 500,
      },
    },
  },
}));

const Chart = memo(({ data }) => {
  const theme = useTheme();

  if (data.length === 0) {
    return (
      <Box width="100%" height={112}>
        <Typography>No data for the last 30 days</Typography>
      </Box>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={112}>
      <LineChart data={data} margin={{ left: -58, bottom: -28 }}>
        <Line dataKey="value" stroke={theme.palette.primary.main} dot={false} />
        <XAxis
          dataKey="date"
          type="number"
          domain={['dataMin', 'dataMax']}
          interval="preserveStartEnd"
          scale="time"
          tick={false}
        />
        <YAxis
          type="number"
          domain={['dataMin - 1', 'dataMax + 1']}
          interval="preserveStartEnd"
          scale="linear"
          tick={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
});

const SmallChartBox = ({ title, data, allDataLoaded }) => {
  const classes = useStyles();
  const history = useHistory();

  if (!data || !allDataLoaded) {
    return (
      <Box mb={1}>
        <Skeleton variant="rect" width="100%" height={174} />
      </Box>
    );
  }

  return (
    <Box className={classes.chartBox} onClick={() => history.push(`?detail=${title}`)}>
      <Box>
        <Typography variant="inherit">{title}</Typography>
        <img src={'/images/expand.svg'} alt="expand" width={12} height={12} />
      </Box>
      <Chart data={data} />
    </Box>
  );
};

export default memo(SmallChartBox);
