import React from 'react';

import { Box, InputAdornment, ListSubheader, TextField, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { VariableSizeList } from 'react-window';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  search: {
    fontSize: '10px !important',

    '& > input::placeholder': {
      fontStyle: 'italic !important',
    },
  },
}));

const renderSearchItem = company => {
  return (
    <Box display="flex" alignItems="center">
      {company.profile_image_url ? (
        <img
          src={company.profile_image_url}
          alt=""
          width={19}
          height={19}
          onError={ev => (ev.target.src = '/images/account-circle.svg')}
        />
      ) : (
        <img src={'/images/account-circle.svg'} alt="account-circle" width={19} height={19} />
      )}
      <Box ml={1} fontSize={10}>
        {company.name}
      </Box>
    </Box>
  );
};

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const AutoSearchBar = ({ companies }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Autocomplete
      freeSolo
      disableListWrap
      options={companies}
      getOptionLabel={company => (company.name ? company.name : company)}
      ListboxComponent={ListboxComponent}
      renderOption={renderSearchItem}
      noOptionsText={<Box fontSize={10}>No results</Box>}
      onChange={(event, newValue) => {
        if (newValue?.key) {
          history.push(`/company/${newValue.key}`);
        } else if (newValue) {
          history.push(`/?filter=${newValue}`);
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder="Search companies"
          variant="outlined"
          fullWidth
          margin="dense"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Box display="flex">
                  <img src={'/images/search-icon.svg'} alt="Search Icon" />
                </Box>
              </InputAdornment>
            ),
            className: classes.search,
          }}
        />
      )}
    />
  );
};

export default AutoSearchBar;
