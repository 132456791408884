import React, { memo, useEffect, useState } from 'react';

import { useHistory } from 'react-router';

import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { format, differenceInCalendarDays } from 'date-fns';

import API_BASE_URL from '../../lib/API_BASE_URL';

import AmountBox from './AmountBox';
import TwitterInOutListItem from './TwitterInOutListItem';
import EmployeeInOutListItem from './EmployeeInOutListItem';

import calcDataDiff from '../../utils/calcDataDiff';
import filterDateRange from '../../utils/filterDateRange';
import DataList from './DataList';

const useStyles = makeStyles(theme => ({
  chartBox: {
    backgroundColor: '#fff',
    boxShadow: '0 0 15px 1px rgba(0,0,0,0.08)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& > div:first-of-type': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      '& > span': {
        fontSize: 12,
        fontWeight: 500,
      },
    },
  },
  chartTooltip: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: 7,
    padding: theme.spacing(1),
    textAlign: 'right',
    '& > p:not(:last-of-type)': {
      fontSize: 12,
      fontWeight: 500,
    },
    '& > p:last-of-type': {
      fontSize: 10,
      fontStyle: 'italic',
    },
  },
  menuBtn: {
    fontSize: 12,
    textTransform: 'capitalize',
    '& .MuiButton-endIcon': {
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 19,
      },
    },
  },
}));

const Chart = memo(({ data, stat, marginLeft }) => {
  const classes = useStyles();
  const theme = useTheme();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box className={classes.chartTooltip}>
          <Typography style={{ textTransform: 'capitalize' }}>
            {payload[0].value} {stat}
          </Typography>
          <Typography>{format(new Date(payload[0].payload.date), 'dd MMMM yyyy')}</Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={325}>
      <LineChart data={data} margin={{ left: marginLeft, bottom: -4 }}>
        <Line dataKey="value" stroke={theme.palette.primary.main} dot={false} />
        <XAxis
          dataKey="date"
          tickFormatter={value => format(new Date(value), 'dd/MM/yyyy')}
          type="number"
          domain={['dataMin', 'dataMax']}
          interval="preserveStartEnd"
          tick={{ fontSize: 10 }}
          tickSize={12}
          scale="time"
        />
        <YAxis
          type="number"
          domain={['dataMin - 1', 'dataMax + 1']}
          interval="preserveStartEnd"
          tick={{ fontSize: 10 }}
          tickSize={12}
          scale="linear"
          allowDecimals={false}
        />
        <Tooltip
          cursor={{ stroke: theme.palette.primary.main, strokeDasharray: 2 }}
          content={<CustomTooltip />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
});

const TwitterDetailsLists = ({ id }) => {
  return (
    <Box mt={3} display="flex">
      <Box flex={1} mr={2}>
        <DataList
          title="New Followers"
          url={`${API_BASE_URL}/api/v1/companies/${id}/twitter/followers/flow`}
          dataTarget="follower_flow"
          direction="in"
          rowRenderer={({ index, style, data }) => {
            const item = data[index];

            return <TwitterInOutListItem key={`${item.date}${index}`} item={item} style={style} />;
          }}
          listHeight={216}
          itemHeight={54}
        />
      </Box>
      <Box flex={1}>
        <DataList
          title="Unfollows"
          url={`${API_BASE_URL}/api/v1/companies/${id}/twitter/followers/flow`}
          dataTarget="follower_flow"
          direction="out"
          rowRenderer={({ index, style, data }) => {
            const item = data[index];

            return <TwitterInOutListItem key={`${item.date}${index}`} item={item} style={style} />;
          }}
          listHeight={216}
          itemHeight={54}
        />
      </Box>
    </Box>
  );
};

const LinkedinDetailsLists = ({ id }) => {
  return (
    <Box mt={3} display="flex">
      <Box flex={1} mr={2}>
        <DataList
          title="Employee Inbound"
          url={`${API_BASE_URL}/api/v1/companies/${id}/linkedin/employees/flow`}
          dataTarget="employee_flow"
          direction="in"
          rowRenderer={({ index, style, data }) => {
            const item = data[index];

            return <EmployeeInOutListItem key={item.id} employee={item} style={style} />;
          }}
          listHeight={216}
          itemHeight={54}
        />
      </Box>
      <Box flex={1}>
        <DataList
          title="Employee Outbound"
          url={`${API_BASE_URL}/api/v1/companies/${id}/linkedin/employees/flow`}
          dataTarget="employee_flow"
          direction="out"
          rowRenderer={({ index, style, data }) => {
            const item = data[index];

            return <EmployeeInOutListItem key={item.id} employee={item} style={style} />;
          }}
          listHeight={216}
          itemHeight={54}
        />
      </Box>
    </Box>
  );
};

const DetailsContent = ({
  id,
  showDetail,
  allDataLoaded,
  growth,
  linkedinEmployees,
  linkedinFollowers,
  twitterFollowers,
  twitterTweets,
  twitterFollowing,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [stat, setStat] = useState(null);
  const [statCounts, setStatCounts] = useState(null);
  const [range, setRange] = useState('all');
  const [data, setData] = useState(null);
  const [dateDiffFirst, setDateDiffFirst] = useState(null);
  const [dateDiffLast, setDateDiffLast] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [marginLeft, setMarginLeft] = useState(-24);
  const [dataLists, setDataLists] = useState(null);

  useEffect(() => {
    try {
      let newData = null;

      let filterDays = null;

      switch (range) {
        case 'all':
          break;

        case 'year':
          filterDays = 365;
          break;

        case 'month':
          filterDays = 30;
          break;

        case 'week':
          filterDays = 7;
          break;

        default:
          break;
      }

      switch (showDetail) {
        case 'GROWTH':
          if (growth) {
            setStatCounts({
              growth: {
                total: filterDateRange(growth, filterDays)[
                  filterDateRange(growth, filterDays).length - 1
                ]?.value,
                count: calcDataDiff(filterDateRange(growth, filterDays)),
              },
            });

            if (!stat) {
              setStat('growth');
            }

            switch (stat) {
              case 'growth':
                newData = [...growth];
                break;

              default:
                break;
            }
          }

          break;

        case 'EMPLOYEES':
          setDataLists(<LinkedinDetailsLists id={id} />);

          if (linkedinEmployees) {
            setStatCounts({
              employees: {
                total: filterDateRange(linkedinEmployees, filterDays)[
                  filterDateRange(linkedinEmployees, filterDays).length - 1
                ]?.value,
                count: calcDataDiff(filterDateRange(linkedinEmployees, filterDays)),
              },
            });

            if (!stat) {
              setStat('employees');
            }

            switch (stat) {
              case 'employees':
                newData = [...linkedinEmployees];
                break;

              default:
                break;
            }
          }

          break;

        case 'LINKEDIN':
          if (linkedinFollowers) {
            setStatCounts({
              followers: {
                total: filterDateRange(linkedinFollowers, filterDays)[
                  filterDateRange(linkedinFollowers, filterDays)?.length - 1
                ]?.value,
                count: calcDataDiff(filterDateRange(linkedinFollowers, filterDays)),
              },
            });

            if (!stat) {
              setStat('followers');
            }

            switch (stat) {
              case 'followers':
                newData = [...linkedinFollowers];
                break;

              default:
                break;
            }
          }

          break;

        case 'TWITTER':
          setDataLists(<TwitterDetailsLists id={id} />);

          if (twitterFollowers) {
            setStatCounts({
              followers: {
                total: filterDateRange(twitterFollowers, filterDays)[
                  filterDateRange(twitterFollowers, filterDays).length - 1
                ]?.value,
                count: calcDataDiff(filterDateRange(twitterFollowers, filterDays)),
              },
              tweets: {
                total: filterDateRange(twitterTweets, filterDays)[
                  filterDateRange(twitterTweets, filterDays).length - 1
                ]?.value,
                count: calcDataDiff(filterDateRange(twitterTweets, filterDays)),
              },
              following: {
                total: filterDateRange(twitterFollowing, filterDays)[
                  filterDateRange(twitterFollowing, filterDays).length - 1
                ]?.value,
                count: calcDataDiff(filterDateRange(twitterFollowing, filterDays)),
              },
            });

            if (!stat) {
              setStat('followers');
            }

            switch (stat) {
              case 'followers':
                newData = [...twitterFollowers];
                break;

              case 'tweets':
                newData = [...twitterTweets];
                break;

              case 'following':
                newData = [...twitterFollowing];
                break;

              default:
                break;
            }
          }

          break;

        default:
          break;
      }

      if (!dateDiffFirst && newData && newData?.length !== 0) {
        setDateDiffFirst(differenceInCalendarDays(new Date(), new Date(newData[0].date)));
        setDateDiffLast(
          differenceInCalendarDays(new Date(), new Date(newData[newData.length - 1].date))
        );
      }

      if (filterDays) {
        newData = filterDateRange(newData, filterDays);
      }

      setData(newData);

      if (newData && newData?.length !== 0) {
        setMarginLeft(-46 + `${newData[newData.length - 1].value + 25}`.length * 5.61);
      }
    } catch (error) {
      console.log('setData error', error);
    }
  }, [stat, range, allDataLoaded]); // eslint-disable-line

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = newRange => {
    setRange(newRange);
    handleMenuClose();
  };

  return (
    <Box mt={3}>
      {data ? (
        <Box className={classes.chartBox}>
          <Box>
            <Typography variant="inherit">{showDetail}</Typography>
            <Box display="flex" alignItems="center">
              <Box mr={1.5}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  endIcon={<KeyboardArrowDownRoundedIcon />}
                  size="small"
                  className={classes.menuBtn}
                  disabled={data.length === 0}
                >
                  {range === 'all' ? 'All Time' : `Past ${range}`}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleSelect('all')} selected={range === 'all'}>
                    All Time
                  </MenuItem>
                  {dateDiffFirst > 365 && dateDiffLast < 366 && (
                    <MenuItem onClick={() => handleSelect('year')} selected={range === 'year'}>
                      Past Year
                    </MenuItem>
                  )}
                  {dateDiffFirst > 30 && dateDiffLast < 31 && (
                    <MenuItem onClick={() => handleSelect('month')} selected={range === 'month'}>
                      Past Month
                    </MenuItem>
                  )}
                  {dateDiffFirst > 6 && dateDiffLast < 7 && (
                    <MenuItem onClick={() => handleSelect('week')} selected={range === 'week'}>
                      Past Week
                    </MenuItem>
                  )}
                </Menu>
              </Box>
              <IconButton size="small" onClick={() => history.push(`/company/${id}`)}>
                <img src={'/images/minimize.svg'} alt="minimize" width={12} height={12} />
              </IconButton>
            </Box>
          </Box>
          <Box>
            {data.length !== 0 ? (
              <Chart data={data} stat={stat} marginLeft={marginLeft} />
            ) : (
              <Box height={325}>
                <Typography>No data to display</Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box mb={1}>
          <Skeleton variant="rect" width="100%" height={402} />
        </Box>
      )}
      <Box display="flex">
        {statCounts ? (
          Object.keys(statCounts).map((statCount, index) => (
            <AmountBox
              key={statCount}
              title={statCount}
              count={statCounts[statCount].count}
              currentTotal={statCounts[statCount].total || '-'}
              stat={stat}
              setStat={setStat}
              last={index === Object.keys(statCounts).length - 1}
              allDataLoaded
            />
          ))
        ) : (
          <Box flex={1}>
            <Skeleton variant="rect" width="100%" height={46} />
          </Box>
        )}
      </Box>
      {dataLists}
    </Box>
  );
};

export default memo(DetailsContent);
