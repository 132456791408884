const calcDataDiff = data => {
  if (data) {
    if (data.length !== 0) {
      return data[data.length - 1].value - data[0].value;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export default calcDataDiff;
