import React, { memo } from 'react';

import { useHistory } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 12,
    fontWeight: '600',
  },
  list: {
    backgroundColor: '#fff',
    minHeight: 120,
  },
  listRow: {
    backgroundColor: '#fff',

    display: 'flex',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 130, 200, 0.1)',
    },
  },
  listRowCell: {
    padding: '4px 0',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const SpecificList = ({ title, rows }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
        {rows === undefined ? (
          <Skeleton width="100%" height={20} />
        ) : (
          <>
            <Typography variant="h4" component="h2" className={classes.title}>
              {title}
            </Typography>
            <Box display="flex" mr={-0.5}>
              <ChevronRightRoundedIcon fontSize="small" />
            </Box>
          </>
        )}
      </Box>
      <Box className={classes.list}>
        {rows === undefined ? (
          <Box>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        ) : rows.length !== 0 ? (
          rows.map(row => {
            return (
              <Box
                key={row.key}
                className={classes.listRow}
                onClick={() => history.push(`/company/${row.key}`)}
              >
                <Box className={classes.listRowCell} flex={1}>
                  <Box display="flex" alignItems="center" width="100%">
                    {row.profile_image_url ? (
                      <img
                        src={row.profile_image_url}
                        alt="profile_image"
                        width={16}
                        height={16}
                        onError={ev => (ev.target.src = '/images/account-circle.svg')}
                      />
                    ) : (
                      <img
                        src={'/images/account-circle.svg'}
                        alt="account-circle"
                        width={16}
                        height={16}
                      />
                    )}
                    <Box ml={1} fontSize={10} className={classes.ellipsisText}>
                      {row.name}
                    </Box>
                  </Box>
                </Box>
                <Box justifyContent="flex-end" className={classes.listRowCell}>
                  <Box fontSize={10} display="flex" justifyContent="flex-end" alignItems="center">
                    {row.growth > 0 && '+'}
                    {row.growth === 0 ? '=' : row.growth}
                    <Box display="flex" ml={0.5}>
                      {row.growth > 0 ? (
                        <img src={'/images/growth-up.svg'} alt="growth Up" width={11} height={12} />
                      ) : row.growth < 0 ? (
                        <img
                          src={'/images/growth-down.svg'}
                          alt="growth Down"
                          width={11}
                          height={12}
                        />
                      ) : (
                        <img
                          src={'/images/growth-same.svg'}
                          alt="growth Same"
                          width={10}
                          height={4}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box>
            <Typography variant="caption">No items in this list</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(SpecificList);
