import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { Box, ThemeProvider, useTheme } from '@material-ui/core';
import theme from './lib/theme';

import { AuthProvider, ProtectedRoute, useAuth } from './context/auth';

import HomePage from './pages/HomePage';
import OverviewPage from './pages/OverviewPage';
import TopNav from './components/TopNav';

const AuthCheck = () => {
  const auth = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);

  useEffect(() => {
    (async () => {
      setIsLoggedIn(await auth.testLogin());
    })();
  }, []); // eslint-disable-line

  if (isLoggedIn === undefined) {
    return null;
  }

  if (isLoggedIn) {
    return <Redirect to="/" />;
  } else {
    return <Redirect to="/login" />;
  }
};

const AppFlow = () => {
  const theme = useTheme();

  const [watchList, setWatchList] = useState([]);

  const updateWatchList = key => {
    const isWatched = watchList.includes(key);
    if (isWatched) {
      const updatedWatchList = watchList.filter(item => item !== key);
      setWatchList([...updatedWatchList]);
    } else {
      setWatchList([...watchList, key]);
    }
  };

  return (
    <Box bgcolor={theme.palette.background.default}>
      <Router>
        <TopNav />
        <Switch>
          <Route path="/authenticated">
            <AuthCheck />
          </Route>
          <ProtectedRoute>
            <Route path="/company/:id">
              <OverviewPage watchList={watchList} updateWatchList={updateWatchList} />
            </Route>
            <Route path="/" exact>
              <HomePage watchList={watchList} />
            </Route>
          </ProtectedRoute>
        </Switch>
      </Router>
    </Box>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <AppFlow />
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
