import React, { useState } from 'react';

import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ClearIcon from '@material-ui/icons/Clear';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  search: {
    fontSize: 10,

    '& > input::placeholder': {
      fontStyle: 'italic',
    },
  },
}));

const SearchBar = ({ addFilter, disabled, shouldNav }) => {
  const classes = useStyles();
  const history = useHistory();

  const [searchVal, setSearchVal] = useState('');

  const handleChange = event => {
    setSearchVal(event.target.value);
  };

  const handleSearch = () => {
    if (shouldNav) {
      history.push(`/?filter=${searchVal}`);
    } else {
      addFilter(searchVal);
      setSearchVal('');
    }
  };

  const handleEnter = event => {
    if (event.keyCode === 13) {
      if (shouldNav) {
        history.push(`/?filter=${searchVal}`);
      } else {
        addFilter(event.target.value);
        setSearchVal('');
      }
    }
  };

  const handleClear = () => {
    setSearchVal('');
  };

  return (
    <TextField
      placeholder="Search companies"
      variant="outlined"
      fullWidth
      margin="dense"
      value={searchVal}
      onChange={handleChange}
      onKeyDown={handleEnter}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton size="small" onClick={handleSearch}>
              <Box display="flex">
                <img src={'/images/search-icon.svg'} alt="Search Icon" />
              </Box>
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {searchVal?.length !== 0 && (
              <IconButton size="small" onClick={handleClear}>
                <Box display="flex" fontSize={13}>
                  <ClearIcon fontSize="inherit" />
                </Box>
              </IconButton>
            )}
          </InputAdornment>
        ),
        className: classes.search,
      }}
    />
  );
};

export default SearchBar;
