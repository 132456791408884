import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router';

import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';

import API_BASE_URL from '../lib/API_BASE_URL';
import axios from 'axios';
import useSWR from 'swr';

import LeftPanel from '../components/Overview/LeftPanel';
import AllContent from '../components/Overview/AllContent';
import DetailsContent from '../components/Overview/DetailsContent';
import RightPanel from '../components/Overview/RightPanel';
import getParameterByName from '../utils/getParameterByName';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    minHeight: 'calc(100vh - 56px)',
    height: 'calc(100vh - 56px)',
    maxHeight: 'calc(100vh - 56px)',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      minHeight: 'calc(100vh - 48px)',
      height: 'calc(100vh - 48px)',
      maxHeight: 'calc(100vh - 48px)',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 64px)',
      height: 'calc(100vh - 64px)',
      maxHeight: 'calc(100vh - 64px)',
    },
    // paddingTop: theme.spacing(5),
    // paddingBottom: theme.spacing(5),
  },
  title: {
    fontSize: 28,
  },
  companyDescItems: {
    '& > div, a': {
      fontSize: 10,
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'flex-end',
      '& > img': {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  companySocialItems: {
    '& > div, a': {
      fontSize: 14,
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: theme.spacing(1),
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
}));

const OverviewPage = ({ watchList, updateWatchList }) => {
  const classes = useStyles();
  const history = useHistory();

  let { id } = useParams();

  const getFetcher = url => axios.get(url, { withCredentials: true }).then(res => res.data);
  const postFetcher = url =>
    fetch(url, { method: 'POST', credentials: 'include' })
      .then(r => r.json())
      .then(res => res);

  let error = false;

  let companies = undefined;
  const { data: companiesData, error: companiesError } = useSWR(
    `${API_BASE_URL}/api/v1/companies/`,
    getFetcher
  );
  if (companiesData) companies = companiesData.companies;

  const { data: company, error: companyError } = useSWR(
    `${API_BASE_URL}/api/v1/companies/${id}`,
    getFetcher
  );

  let growth = undefined;
  const { data: growthData, error: growthError } = useSWR(
    `${API_BASE_URL}/api/v1/companies/${id}/metrics/growth`,
    postFetcher
  );
  if (growthData) growth = growthData.metrics;

  let linkedinEmployees = undefined;
  const { data: linkedinEmployeesData, error: linkedinEmployeesError } = useSWR(
    `${API_BASE_URL}/api/v1/companies/${id}/metrics/linkedin/employees`,
    postFetcher
  );
  if (linkedinEmployeesData) linkedinEmployees = linkedinEmployeesData.metrics;

  let linkedinFollowers = undefined;
  const { data: linkedinFollowersData, error: linkedinFollowersError } = useSWR(
    `${API_BASE_URL}/api/v1/companies/${id}/metrics/linkedin/followers`,
    postFetcher
  );
  if (linkedinFollowersData) linkedinFollowers = linkedinFollowersData.metrics;

  let twitterFollowers = undefined;
  const { data: twitterFollowersData, error: twitterFollowersError } = useSWR(
    `${API_BASE_URL}/api/v1/companies/${id}/metrics/twitter/followers`,
    postFetcher
  );
  if (twitterFollowersData) twitterFollowers = twitterFollowersData.metrics;

  let twitterTweets = undefined;
  const { data: twitterTweetsData, error: twitterTweetsError } = useSWR(
    `${API_BASE_URL}/api/v1/companies/${id}/metrics/twitter/tweets`,
    postFetcher
  );
  if (twitterTweetsData) twitterTweets = twitterTweetsData.metrics;

  let twitterFollowing = undefined;
  const { data: twitterFollowingData, error: twitterFollowingError } = useSWR(
    `${API_BASE_URL}/api/v1/companies/${id}/metrics/twitter/following`,
    postFetcher
  );
  if (twitterFollowingData) twitterFollowing = twitterFollowingData.metrics;

  let allDataLoaded =
    Boolean(companies) &&
    Boolean(company) &&
    Boolean(growth) &&
    Boolean(linkedinEmployees) &&
    Boolean(linkedinFollowers) &&
    Boolean(twitterFollowers) &&
    Boolean(twitterTweets) &&
    Boolean(twitterFollowing);

  if (
    companiesError ||
    companyError ||
    growthError ||
    linkedinEmployeesError ||
    linkedinFollowersError ||
    twitterFollowersError ||
    twitterTweetsError ||
    twitterFollowingError
  ) {
    console.log('error');
    // error = true;
  }

  const PageHeader = () => {
    if (!company) {
      return (
        <Box display="flex" alignItems="center">
          <Skeleton variant="rect" width={76} height={76} />
          <Box ml={1.5} flex={0.5}>
            <Box>
              <Skeleton height={28} />
            </Box>
            <Box mt={1}>
              <Skeleton height={12} />
            </Box>
            <Box mt={1}>
              <Skeleton height={14} />
            </Box>
          </Box>
        </Box>
      );
    }

    let websiteUrl = company.website;
    if (websiteUrl && !websiteUrl.startsWith('http')) {
      websiteUrl = `https://${websiteUrl}`;
    }

    const isWatched = watchList.includes(company.key);

    return (
      <Box display="flex" alignItems="center">
        {company.profile_image_url ? (
          <img
            src={company.profile_image_url}
            alt="profile_image"
            width={76}
            height={76}
            onError={ev => (ev.target.src = '/images/account-circle.svg')}
          />
        ) : (
          <img src={'/images/account-circle.svg'} alt="account-circle" width={69} height={69} />
        )}
        <Box ml={1.5}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.title}>{company.name}</Typography>
            <Box display="flex" ml={1}>
              <IconButton size="small" onClick={() => updateWatchList(company.key)}>
                {isWatched ? <StarIcon color="primary" /> : <StarOutlineIcon color="primary" />}
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" className={classes.companyDescItems}>
            {websiteUrl && (
              <a href={`${websiteUrl}`} target="_blank" rel="noreferrer">
                <img src={'/images/hyperlink.svg'} alt="hyperlink" width={12} height={12} />
                {websiteUrl}
              </a>
            )}
            {Boolean(company.location) && (
              <a
                href={`https://maps.google.com/?q=${company.location}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={'/images/location.svg'} alt="hyperlink" width={12} height={12} />
                {company.location}
              </a>
            )}
            {Boolean(company.employee_range) && (
              <Box>
                <img src={'/images/person.svg'} alt="hyperlink" width={12} height={12} />
                {company.employee_range} employees
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" className={classes.companySocialItems} mt={1}>
            {Boolean(company.linkedin) && (
              <a href={company.linkedin.url} target="_blank" rel="noreferrer">
                <LinkedInIcon fontSize="inherit" />
              </a>
            )}
            {Boolean(company.twitter) && (
              <a href={company.twitter.url} target="_blank" rel="noreferrer">
                <TwitterIcon fontSize="inherit" />
              </a>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const [showDetail, setShowDetail] = useState(undefined);

  useEffect(() => {
    if (getParameterByName('detail')) {
      setShowDetail(getParameterByName('detail'));
    } else {
      setShowDetail(null);
    }

    const unlisten = history.listen(location => {
      if (location.search.includes('?detail=')) {
        setShowDetail(getParameterByName('detail'));
      } else {
        setShowDetail(null);
      }
    });
    return () => {
      unlisten();
    };
  }, []); // eslint-disable-line

  if (error) {
    return (
      <Box className={classes.container} display="flex" alignItems="center" justifyContent="center">
        <Typography>Something went wrong</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box width={200} bgcolor="#fff" p={3}>
        <LeftPanel companies={companies} watchList={watchList} />
      </Box>
      <Box flex={1} p={4}>
        <PageHeader />
        {showDetail !== undefined && (
          <>
            {!showDetail ? (
              <AllContent
                id={id}
                growth={growth}
                linkedinEmployees={linkedinEmployees}
                linkedinFollowers={linkedinFollowers}
                twitterFollowers={twitterFollowers}
                company={company}
                allDataLoaded={allDataLoaded}
              />
            ) : (
              <DetailsContent
                id={id}
                showDetail={showDetail}
                allDataLoaded={allDataLoaded}
                growth={growth}
                linkedinEmployees={linkedinEmployees}
                linkedinFollowers={linkedinFollowers}
                twitterFollowers={twitterFollowers}
                twitterTweets={twitterTweets}
                twitterFollowing={twitterFollowing}
              />
            )}
          </>
        )}
      </Box>
      <Box
        id="rightPanelId"
        minWidth={325}
        width={325}
        bgcolor="#fff"
        py={3}
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <RightPanel id={id} />
      </Box>
    </Box>
  );
};

export default OverviewPage;
