import React, { memo } from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import GrowthUpIcon from '../../svg/GrowthUpIcon';
import GrowthDownIcon from '../../svg/GrowthDownIcon';
import GrowthSameIcon from '../../svg/GrowthSameIcon';

const useStyles = makeStyles(theme => ({
  amountBox: {
    backgroundColor: '#fff',
    boxShadow: '0 0 15px 1px rgba(0,0,0,0.08)',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      fontSize: 12,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
  listTitle: {
    fontSize: 18,
  },
}));

const AmountBox = ({ title, count, currentTotal, stat, setStat, last, allDataLoaded }) => {
  const classes = useStyles();
  const theme = useTheme();

  const clickAble = Boolean(setStat);

  const active = stat === title;
  const handleClick = () => {
    setStat(title);
  };

  if ((count === undefined && currentTotal === undefined) || !allDataLoaded) {
    return <Skeleton variant="rect" width="100%" height={46} />;
  }

  return (
    <Box
      className={classes.amountBox}
      flex={1}
      mr={!last ? 1 : 0}
      onClick={clickAble ? handleClick : null}
      color={active ? theme.palette.primary.main : 'inherit'}
      style={{
        cursor: clickAble ? 'pointer' : 'default',
        boxShadow: active ? `0 0 6px 1px ${theme.palette.primary.main}` : '',
      }}
    >
      <Typography variant="inherit">{title}</Typography>
      <Box
        fontSize={12}
        display="flex"
        alignItems="center"
        color={active ? 'inherit' : count > 0 ? '#14B14D' : count < 0 ? '#B11414' : '#D8D8D8'}
      >
        {currentTotal !== undefined && (
          <Box mr={0.5} color={active ? 'inherit' : '#717171'}>
            <Typography variant="inherit">({currentTotal})</Typography>
          </Box>
        )}
        <Typography variant="inherit">{count}</Typography>
        <Box display="flex" ml={0.5}>
          {count > 0 ? (
            <GrowthUpIcon width={11} height={12} />
          ) : count < 0 ? (
            <GrowthDownIcon width={11} height={12} />
          ) : (
            <GrowthSameIcon width={10} height={4} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(AmountBox);
