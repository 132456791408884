import React, { memo, useState } from 'react';

import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import EmployeeInOutListItem from './EmployeeInOutListItem';
import JobListItem from './JobListItem';

import { FixedSizeList } from 'react-window';

import SmallChartBox from './SmallChartBox';
import AmountBox from './AmountBox';

import calcDataDiff from '../../utils/calcDataDiff';
import filterDateRange from '../../utils/filterDateRange';
import DataList from './DataList';
import API_BASE_URL from '../../lib/API_BASE_URL';

const useStyles = makeStyles(theme => ({
  listTitle: {
    fontSize: 18,
  },
  tab: {
    minWidth: 'unset',
    maxWidth: 'unset',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dataList-tabpanel-${index}`}
      aria-labelledby={`dataList-tab-${index}`}
      {...other}
    >
      <Box pt={1}>{children}</Box>
    </div>
  );
};

const AllContent = ({
  id,
  growth,
  linkedinEmployees,
  linkedinFollowers,
  twitterFollowers,
  company,
  allDataLoaded,
}) => {
  const classes = useStyles();

  const JobRow = memo(({ data, index, style }) => {
    const job = data[index];

    return (
      <Box key={job.key} style={style}>
        <JobListItem job={job} />
      </Box>
    );
  });

  const LoadingSkeleton = () => {
    return (
      <>
        <Box py={0.5}>
          <Skeleton variant="rect" width="100%" height={46} />
        </Box>
        <Box py={0.5}>
          <Skeleton variant="rect" width="100%" height={46} />
        </Box>
        <Box py={0.5}>
          <Skeleton variant="rect" width="100%" height={46} />
        </Box>
        <Box py={0.5}>
          <Skeleton variant="rect" width="100%" height={46} />
        </Box>
        <Box py={0.5}>
          <Skeleton variant="rect" width="100%" height={46} />
        </Box>
        <Box py={0.5}>
          <Skeleton variant="rect" width="100%" height={46} />
        </Box>
        <Box py={0.5}>
          <Skeleton variant="rect" width="100%" height={46} />
        </Box>
      </>
    );
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const a11yProps = index => {
    return {
      id: `dataList-tab-${index}`,
      'aria-controls': `dataList-tabpanel-${index}`,
    };
  };

  return (
    <>
      <Box mt={3}>
        <Box mb={1}>
          {allDataLoaded ? (
            <Typography className={classes.listTitle}>
              Quick overview off the company in the last 30 days
            </Typography>
          ) : (
            <Skeleton height={27} />
          )}
        </Box>
        <Box display="flex">
          <Box flex={1} mr={1.5}>
            <SmallChartBox
              title="GROWTH"
              data={filterDateRange(growth, 30)}
              allDataLoaded={allDataLoaded}
            />
            <AmountBox
              title="POSITION"
              count={calcDataDiff(filterDateRange(growth, 30))}
              currentTotal={growth && growth.length !== 0 ? growth[growth.length - 1].value : '-'}
              last
              allDataLoaded={allDataLoaded}
            />
          </Box>
          <Box flex={1} mr={1.5}>
            <SmallChartBox
              title="EMPLOYEES"
              data={filterDateRange(linkedinEmployees, 30)}
              allDataLoaded={allDataLoaded}
            />
            <AmountBox
              title="EMPLOYEES"
              count={calcDataDiff(filterDateRange(linkedinEmployees, 30))}
              currentTotal={
                linkedinEmployees && linkedinEmployees.length !== 0
                  ? linkedinEmployees[linkedinEmployees.length - 1].value
                  : '-'
              }
              last
              allDataLoaded={allDataLoaded}
            />
          </Box>
          <Box flex={1} mr={1.5}>
            <SmallChartBox
              title="LINKEDIN"
              data={filterDateRange(linkedinFollowers, 30)}
              allDataLoaded={allDataLoaded}
            />
            <AmountBox
              title="FOLLOWERS"
              count={calcDataDiff(filterDateRange(linkedinFollowers, 30))}
              currentTotal={
                linkedinFollowers && linkedinFollowers.length !== 0
                  ? linkedinFollowers[linkedinFollowers.length - 1].value
                  : '-'
              }
              last
              allDataLoaded={allDataLoaded}
            />
          </Box>
          <Box flex={1}>
            <SmallChartBox
              title="TWITTER"
              data={filterDateRange(twitterFollowers, 30)}
              allDataLoaded={allDataLoaded}
            />
            <AmountBox
              title="FOLLOWERS"
              count={calcDataDiff(filterDateRange(twitterFollowers, 30))}
              currentTotal={
                twitterFollowers && twitterFollowers.length !== 0
                  ? twitterFollowers[twitterFollowers.length - 1].value
                  : '-'
              }
              last
              allDataLoaded={allDataLoaded}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        {allDataLoaded ? (
          <Tabs
            value={activeTab}
            onChange={handleChange}
            aria-label="dataList-tab"
            indicatorColor="primary"
          >
            <Tab label="Employees" {...a11yProps(0)} className={classes.tab} />
            <Tab label="Job Offerings" {...a11yProps(1)} className={classes.tab} />
            <Tab label="Glassdoor" {...a11yProps(2)} className={classes.tab} />
          </Tabs>
        ) : (
          <Skeleton variant="rect" width="100%" height={48} />
        )}
        <TabPanel value={activeTab} index={0}>
          <DataList
            url={`${API_BASE_URL}/api/v1/companies/${id}/linkedin/employees/flow`}
            dataTarget="employee_flow"
            direction="all"
            rowRenderer={({ index, style, data }) => {
              const item = data[index];

              return <EmployeeInOutListItem key={item.id} employee={item} style={style} />;
            }}
            listHeight={378}
            itemHeight={54}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Box mt={1}>
            {company ? (
              company.jobs.length !== 0 ? (
                <FixedSizeList
                  height={378}
                  itemCount={company.jobs.length}
                  itemSize={54}
                  itemData={company.jobs}
                >
                  {JobRow}
                </FixedSizeList>
              ) : (
                <Box>
                  <Typography variant="caption">No items in this list</Typography>
                </Box>
              )
            ) : (
              <LoadingSkeleton />
            )}
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Box mt={1}>
            <Typography variant="caption">No items in this list</Typography>
          </Box>
        </TabPanel>
      </Box>
    </>
  );
};

export default memo(AllContent);
