import React, { memo, useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import { useSWRInfinite } from 'swr';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';

const useStyles = makeStyles(theme => ({
  listTitle: {
    fontSize: 18,
  },
}));

const DataList = ({
  id,
  title,
  url,
  dataTarget,
  direction,
  rowRenderer,
  listHeight,
  itemHeight,
}) => {
  const classes = useStyles();

  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (!hasMore) {
      setHasMore(true);
    }
  }, [id]); // eslint-disable-line

  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.follower_flow) return null;

    if (pageIndex === 0) return [url, '', direction];

    return [url, previousPageData.next_cursor, direction];
  };

  const fetcher = async (url, cursor = '', direction) => {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        direction,
        cursor,
        size: 100,
      }),
    });
    return await res.json();
  };

  const totalRows = useRef(null);

  let data = [];

  const { data: swrData, size, setSize } = useSWRInfinite(getKey, fetcher);

  if (swrData) {
    if (swrData.length !== 0) {
      totalRows.current = swrData[0].total;
      if (!swrData[swrData.length - 1].has_more && hasMore) {
        setHasMore(false);
      }
      swrData.forEach(page => {
        data = [...data, ...page[dataTarget]];
      });
    }
  }

  const isRowLoaded = ({ index }) => {
    return !!data[index];
  };

  const LoadingSkeleton = () => {
    const skeletonArr = [...Array(listHeight / itemHeight).keys()];

    return (
      <>
        {skeletonArr.map(v => (
          <Box py={0.5} key={v}>
            <Skeleton variant="rect" width="100%" height={itemHeight - 8} />
          </Box>
        ))}
      </>
    );
  };

  return (
    <>
      {title && (
        <>
          {data.length !== 0 ? (
            <Typography className={classes.listTitle}>{title}</Typography>
          ) : (
            <Skeleton height={27} />
          )}
        </>
      )}
      <Box mt={1} height={listHeight}>
        {data.length !== 0 ? (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={() => setSize(size + 1)}
            rowCount={totalRows.current}
            minimumBatchSize={100}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    width={width}
                    rowCount={data.length}
                    rowHeight={itemHeight}
                    rowRenderer={props => rowRenderer({ ...props, data })}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        ) : (
          <LoadingSkeleton />
        )}
      </Box>
    </>
  );
};

export default memo(DataList);
