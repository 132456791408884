import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// $emporer: #555555;
// $lochmara: #0082C8;
// $bright-turquoise: #00C8FF;
// $caribbean-green: #00CD87;
// $jaffa: #EB6937;
// $curious-blue: #268CCC;
// $bombay: #AAAAAC;

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0082c8',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00CD87',
    },
    background: {
      default: '#ededed',
    },
    text: {
      primary: '#717171',
    },
    error: {
      main: '#FF6161',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
